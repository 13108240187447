const IMAGES_BASE_URL = "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/"


const IMAGE_ASSETS = {
    signature: IMAGES_BASE_URL + "signature.png",
    meBackground:IMAGES_BASE_URL + "me-background.jpg",
    me:IMAGES_BASE_URL + "me.jpg",
    
    neo4j: IMAGES_BASE_URL + "Neo4j.png",
    aws: IMAGES_BASE_URL + "AWS.png",
    amazonAlexa: IMAGES_BASE_URL + "AmazonAlexa.png",
    docker: IMAGES_BASE_URL + "Docker.png",
    flask: IMAGES_BASE_URL + "Flask.png",
    jupyter: IMAGES_BASE_URL + "Jupyter.png",
    mongo: IMAGES_BASE_URL + "Mongo.png",
    mysql: IMAGES_BASE_URL + "MySQL.png",
    nginx: IMAGES_BASE_URL + "Nginx.png",
    node: IMAGES_BASE_URL + "Node.png",
    python: IMAGES_BASE_URL + "Python.png",
    dynamoDB: IMAGES_BASE_URL + "DynamoDB.png",
    githubActions: IMAGES_BASE_URL + "GitHub-Actions.png",
    linux: IMAGES_BASE_URL + "Linux.png",
    redis: IMAGES_BASE_URL + "Redis.png",
    haskell: IMAGES_BASE_URL + "63064c5652d40eda2eb7a838_33ac2334.png",
    rust: IMAGES_BASE_URL + "Rust.png",
    googleCloud: IMAGES_BASE_URL + "google-cloud-logo-0.png",
    selenium: IMAGES_BASE_URL + "selenium.png",
    whatsappCloudAPI: IMAGES_BASE_URL + "wppCloudApi.png",

    googleGemini: IMAGES_BASE_URL + "Google_Gemini_logo.png",
    langchain: IMAGES_BASE_URL + "langchain.png",
    openAI: IMAGES_BASE_URL + "openai.png"


}





export default IMAGE_ASSETS;