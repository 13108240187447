import react, {useEffect, useState} from "react";
import STRINGS from "../resources/strings";
import ASSETS from "../resources/imageAssets";

const Home = ({language}) => {
  
  return (
    <>
 
    <section id="home">
      <div className="container">
        <div className="roww">
          {/* Main Title */}
          <div className="resumo_fn_main_title">
            <h3 className="subtitle">{STRINGS.introduction[language]}</h3>
            <h3 className="title">{STRINGS.titleJob[language]}</h3>
            <p className="desc" style={{whiteSpace: "pre-line"}}>
                {STRINGS.introductionSummary[language]}
            </p>
            {/* <p className="desc">
              {STRINGS.introductionSummaryPart2[language]}
            </p> */}
            <img src={ASSETS.signature} alt="signature" />
          </div>
          {/* /Main Title */}
        </div>
      </div>
    </section>
    </>

  );
};

export default Home;
