import { useState } from "react";
import STRINGS from "../resources/strings";
// import Moment from 'react-moment';
import IMAGE_ASSETS from "../resources/imageAssets";
import PERSONAL_ASSETS from "../resources/personalAssets";

const About = ({language}) => {
  const [toggleList, setToggleList] = useState("tab1");
  // const [today, setToday] = useState(getToday());
  const activeList = (value) => (value === toggleList ? "active" : "");

  const EXTRAS = [

      {
        institute: "Laboratório Nacional de Computação Científica (LNCC)",
        course: STRINGS.introductionMachineLearning[language],
        year: 2020 ,
        type: STRINGS.summerSchool[language],
        hasAsset: true,
        assetLink: PERSONAL_ASSETS.introductionMachineLearing
      },



      {
        institute: "Laboratório Nacional de Computação Científica (LNCC)",
        course: STRINGS.signalAnalisys[language],
        year: 2020 ,
        type: STRINGS.summerSchool[language],
        hasAsset: true,
        assetLink: PERSONAL_ASSETS.signalAnalisys
      },


      {
        institute: "Laboratório Nacional de Computação Científica (LNCC)",
        course: STRINGS.introductionPythonParsl[language],
        year: 2021 ,
        type: STRINGS.summerSchool[language],
        hasAsset: true,
        assetLink: PERSONAL_ASSETS.pythonParsl
      },


      {
        institute: "Laboratório Nacional de Computação Científica (LNCC)",
        course: STRINGS.introductionDeepLearning[language],
        year: 2021,
        type: STRINGS.summerSchool[language],
        hasAsset: true,
        assetLink: PERSONAL_ASSETS.introductionDeepLearning
      },


      {
        institute: "Laboratório Nacional de Computação Científica (LNCC)",
        course: STRINGS.bigDataAlgorithms[language],
        year: 2021,
        type: STRINGS.summerSchool[language],
        hasAsset: true,
        assetLink: PERSONAL_ASSETS.bigDataAlgorithms
      },


      {
        institute: "Laboratório Nacional de Computação Científica (LNCC)",
        course: STRINGS.deepLearingMiniCourse[language],
        year: 2021,
        type: STRINGS.summerSchool[language],
        hasAsset: true,
        assetLink: PERSONAL_ASSETS.deepLearingMiniCourse
      },



      {
        institute: "Instituto Tecnológico da Aeronáutica (ITA)",
        course: STRINGS.designPatterns[language],
        year: 2023,
        type: STRINGS.extensionCourse[language],
        hasAsset: false,
      },



  ]


  const EXPERIENCES = [
    {
      company: "WeFit Digital Service Design",
      period: `2024 — ${STRINGS.today[language]}`,
      occupation: STRINGS.wefitJobTitleTechDesigner[language],
      // description: STRINGS.mentorappJobDescriptionSoftwareEngineer[language]
    },
    {
      company: "MentorApp Inovação e Tecnologias Educacionais",
      period: `2022 — 2022`,
      occupation: STRINGS.mentorappJobTitleSoftwareEngineer[language],
      description: STRINGS.mentorappJobDescriptionSoftwareEngineer[language]
    },


    {
      company: "MentorApp Inovação e Tecnologias Educacionais",
      period: `2020 — 2022`,
      occupation: STRINGS.internText[language],
      description: STRINGS.mentorappJobDescriptionIntern[language]
    },
    {
      company: "Câmara Municipal de Petrópolis",
      period: `2018 — 2020`,
      occupation: STRINGS.internText[language],
      description: STRINGS.camaraJobDescriptionIntern[language]
    },



  ]


  return (
    <section id="about">
      <div className="container">
        <div className="roww">
          {/* Main Title */}
          <div className="resumo_fn_main_title">
            <h3 className="subtitle">{STRINGS.aboutMe[language]}</h3>
            <h3 className="title">{STRINGS.biography[language]}</h3>
            <p className="desc" style={{whiteSpace: "pre-line"}}>
              {STRINGS.biographyText[language]}
            </p>
          </div>
          {/* /Main Title */}
          {/* About Information */}
          <div className="resumo_fn_about_info">
            <div className="about_left">
              <table>
                <tbody>
                  <tr>
                    <th>{STRINGS.name[language]}</th>
                    <th>Emmanuel Oliveira</th>
                  </tr>
                  <tr>
                    <th>{STRINGS.address[language]}</th>
                    <th>Petrópolis, Rio de Janeiro</th>
                  </tr>
                  <tr>
                    <th>GITHUB</th>
                    <th>
                      <a target="_blank" rel="noopener noreferrer" href="https://github.com/emmanuel-oliveira">emmanuel-oliveira</a>
                    </th>
                  </tr>
                  <tr>
                    <th>LINKEDIN</th>
                    <th>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/emmanuel-oliveiras">Emmanuel Oliveira</a>
                    </th>
                  </tr>
                  <tr>
                    <th>LATTES</th>
                    <th>
                      <a target="_blank" rel="noopener noreferrer" href="https://lattes.cnpq.br/5317454576207400">Emmanuel Oliveira</a>
                    </th>
                  </tr>

                  {/* <tr>
                    <th>Lattes</th>
                    <th>
                      <a target="_blank" rel="noopener noreferrer" href="http://lattes.cnpq.br/5317454576207400">Emmanuel Oliveira</a>
                    </th>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className="about_right">
              {/* Download CV Button */}
              <div className="resumo_fn_cv_btn">
                <a target="_blank" rel="noopener noreferrer" href={language == "pt" ? PERSONAL_ASSETS.cvPt :PERSONAL_ASSETS.cvEn } download>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 449.306 449.306"
                      style={{ enableBackground: "new 0 0 449.306 449.306" }}
                      xmlSpace="preserve"
                      className="fn__svg replaced-svg"
                    >
                      <path d="M447.739,251.298l-59.037-126.433c-1.731-3.54-5.484-5.625-9.404-5.224h-50.155c-5.771,0-10.449,4.678-10.449,10.449     c0,5.771,4.678,10.449,10.449,10.449h43.363l48.588,104.49h-59.559c-27.004-0.133-51.563,15.625-62.694,40.229     c-8.062,16.923-25.141,27.698-43.886,27.69h-60.604c-18.745,0.008-35.823-10.767-43.886-27.69     c-11.131-24.604-35.69-40.362-62.694-40.229H29.257l57.469-104.49h33.437c5.771,0,10.449-4.678,10.449-10.449     c0-5.771-4.678-10.449-10.449-10.449H80.457c-3.776-0.358-7.425,1.467-9.404,4.702L2.09,250.776     c-1.209,1.072-1.958,2.569-2.09,4.18v130.09c0.832,29.282,24.524,52.744,53.812,53.29h341.682     c29.289-0.546,52.98-24.008,53.812-53.29v-130.09C449.107,253.622,448.567,252.362,447.739,251.298z M428.408,385.045     c-0.812,17.743-15.16,31.864-32.914,32.392H53.812c-17.754-0.528-32.102-14.648-32.914-32.392V265.927h66.873     c18.745-0.008,35.823,10.767,43.886,27.69c11.131,24.604,35.69,40.362,62.694,40.229h60.604     c27.004,0.133,51.563-15.625,62.694-40.229c8.062-16.923,25.141-27.698,43.886-27.69h66.873V385.045z" />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 449.306 449.306"
                      style={{ enableBackground: "new 0 0 449.306 449.306" }}
                      xmlSpace="preserve"
                      className="fn__svg arrow replaced-svg"
                    >
                      <path d="M217.339,252.865c3.706,4.04,9.986,4.31,14.025,0.603c0.21-0.192,0.411-0.394,0.603-0.603l71.053-71.576   c3.462-4.617,2.527-11.166-2.09-14.629c-3.715-2.786-8.824-2.786-12.539,0l-53.29,53.29V21.42   c0-5.771-4.678-10.449-10.449-10.449s-10.449,4.678-10.449,10.449v198.531l-53.29-53.29c-4.617-3.462-11.166-2.527-14.629,2.09   c-2.786,3.715-2.786,8.824,0,12.539L217.339,252.865z" />
                    </svg>
                  </span>
                  <span>Download CV</span>
                </a>
              </div>
              {/* /Download CV Button */}
            </div>
          </div>
          {/* /About Information */}
          {/* Tabs Shortcode */}
          <div className="resumo_fn_tabs">
            {/* Tab: Header */}
            <div className="tab_header">
              <ul>
                <li className={activeList("tab1")}>
                  <a target="_blank" rel="noopener noreferrer" href="#" onClick={() => setToggleList("tab1")}>
                    {STRINGS.experience[language]}
                  </a>
                </li>
                <li className={activeList("tab2")}>
                  <a target="_blank" rel="noopener noreferrer" href="#" onClick={() => setToggleList("tab2")}>
                  {STRINGS.education[language]}
                  </a>
                </li>
                <li className={activeList("tab3")}>
                  <a target="_blank" rel="noopener noreferrer" href="#" onClick={() => setToggleList("tab3")}>
                  Extras
                  </a>
                </li>
                <li className={activeList("tab4")}>
                  <a target="_blank" rel="noopener noreferrer" href="#" onClick={() => setToggleList("tab4")}>
                  Skills
                  </a>
                </li>
              </ul>
            </div>
            {/* /Tab: Header */}
            {/* Tab: Content */}
            <div className="tab_content">
              {/* #1 tab content */}
              <div id="tab1" className={`tab_item ${activeList("tab1")}`}>
                {/* Boxed List */}
                <div className="resumo_fn_boxed_list">
                  <ul>
                    {EXPERIENCES.map((experience) => 
                    
                    
                    <li>
                      <div className="item">
                        <div className="item_top">
                          <h5>{experience.company}</h5>
                          <span>{experience.period}</span>
                        </div>
                        <h3>{experience.occupation}</h3>
                        <p>
                           {experience.description}
                        </p>
                      </div>
                    </li>
                    
                    
                    )}








                  </ul>
                </div>
                {/* /Boxed List */}
              </div>
              {/* /#1 tab content */}
              {/* #2 tab content */}
              <div id="tab2" className={`tab_item ${activeList("tab2")}`}>
                {/* Boxed List */}
                <div className="resumo_fn_boxed_list">
                  <ul>
                    <li>
                      <div className="item">
                        <div className="item_top">
                          <h5>{STRINGS.faeterjTitle[language]}</h5>
                          <span>( 2020 — 2024 )</span>
                        </div>
                        <h3>{STRINGS.faeterjCourseDescription[language]}</h3>
                        <p>
                          {STRINGS.faeterjCourseTitle[language]}
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="item">
                        <div className="item_top">
                          <h5>{STRINGS.faetecTitle[language]}</h5>
                          <span>( 2015 — 2018 )</span>
                        </div>
                        <h3>{STRINGS.faeteCourseDescription[language]}</h3>
                        <p>
                         {STRINGS.faeteCourseTitle[language]}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* /Boxed List */}
              </div>
              {/* /#2 tab content */}
              {/* #3 tab content */}
              <div id="tab3" className={`tab_item ${activeList("tab3")}`}>
                {/* Boxed List */}
                <div className="resumo_fn_boxed_list">
                  <ul>
                    {EXTRAS.sort((a, b) => b.year - a.year).map((extra) => 
                    <li>
                    <div className="item">
                      <div className="item_top">
                        <h5>{extra.institute}</h5>
                        <span>{extra.year}</span>
                      </div>
                      {extra.hasAsset ? 
                                    <> 
                                          <a target="_blank" rel="noopener noreferrer" href={extra.assetLink} style={{textDecoration: "none", color: "inherit"}}>
                                              <h3>{extra.course}</h3>
                                          </a>
                                    </> : 
                                    <>
                                    <h3>{extra.course}</h3>
                                    </>
                      }
     
                      <p>
                      {extra.type}
                      </p>
                    </div>
                  </li>
                    )}
                  </ul>
                </div>
                {/* /Boxed List */}
              </div>
              <div id="tab4" className={`tab_item ${activeList("tab4")}`}>
                {/* Progress Bar */}
                <div className="resumo_fn_progress_bar">
                  <div className="progress_item open" data-value={95}>
                    <div className="item_in">
                      <h3 className="progress_title">Python</h3>
                      <span
                        className="progress_percent"
                        style={{ right: "5%", color: "#f1fa8c" }}
                      >
                        95%
                      </span>
                      <div className="bg_wrap">
                        <div className="progress_bg" style={{ width: "95%", backgroundColor: "#f1fa8c" }} />
                      </div>
                    </div>
                  </div>

                  <div className="progress_item open" data-value={75}>
                    <div className="item_in">
                      <h3 className="progress_title">Flask</h3>
                      <span
                        className="progress_percent"
                        style={{ right: "25%", color: "#f8f8f2"}}
                      >
                        75%
                      </span>
                      <div className="bg_wrap">
                        <div className="progress_bg" style={{ width: "75%", backgroundColor: "#f8f8f2" }} />
                      </div>
                    </div>
                  </div>

                  <div className="progress_item open" data-value={60}>
                    <div className="item_in">
                      <h3 className="progress_title">Pandas &amp; Spacy &amp; Sckit-Learn</h3>
                      <span
                        className="progress_percent"
                        style={{ right: "40%", color: "#ff79c6" }}
                      >
                        60%
                      </span>
                      <div className="bg_wrap">
                        <div className="progress_bg" style={{ width: "60%", backgroundColor: "#ff79c6" }} />
                      </div>
                    </div>
                  </div>


                  <div className="progress_item open" data-value={50}>
                    <div className="item_in">
                      <h3 className="progress_title">Node</h3>
                      <span
                        className="progress_percent"
                        style={{ right: "50%", color:"#50fa7b" }}
                      >
                        50%
                      </span>
                      <div className="bg_wrap">
                        <div className="progress_bg" style={{ width: "50%", backgroundColor: "#50fa7b" }} />
                      </div>
                    </div>
                  </div>

                  <div className="progress_item open" data-value={30}>
                    <div className="item_in">
                      <h3 className="progress_title">Typescript</h3>
                      <span
                        className="progress_percent"
                        style={{ right: "70%", color: "#8be9fd"}}
                      >
                        30%
                      </span>
                      <div className="bg_wrap">
                        <div className="progress_bg" style={{ width: "30%", backgroundColor: "#8be9fd"}} />
                      </div>
                    </div>
                  </div>

                  <div className="progress_item open" data-value={75}>
                    <div className="item_in">
                      <h3 className="progress_title">AWS</h3>
                      <span
                        className="progress_percent"
                        style={{ right: "25%", color:"#ffb86c" }}
                      >
                        75%
                      </span>
                      <div className="bg_wrap">
                        <div className="progress_bg" style={{ width: "75%", backgroundColor: "#ffb86c" }} />
                      </div>
                    </div>
                  </div>

                  <div className="progress_item open" data-value={20}>
                    <div className="item_in">
                      <h3 className="progress_title">Rust</h3>
                      <span
                        className="progress_percent"
                        style={{ right: "80%", color:"#ff6c6c" }}
                      >
                        20%
                      </span>
                      <div className="bg_wrap">
                        <div className="progress_bg" style={{ width: "20%", backgroundColor: "#ff6c6c" }} />
                      </div>
                    </div>
                  </div>


                </div>

                {/* /Progress Bar */}
                {/* Description */}
              </div>
              {/* /#2 tab content */}
            </div>
            {/* /Tab: Content */}
          </div>
          {/* /Tabs Shortcode */}
        </div>
      </div>
    </section>
  );
};

export default About;
