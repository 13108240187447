const STRINGS = {
    introduction: {
            pt: "Introdução",
            en: "Introduction"
   },

   titleJob: {
        pt: "Backend Developer",
        en: "Backend Developer"
   },


   introductionSummary : {
        pt: "Desenvolvedor de Back-End Júnior orientado a objetivos com experiência em infraestrutura em nuvem, machine learning, I.A. generativa, sistemas de recomendação e design de APIs da Web.\n\nSou criativo, possuo uma forte capacidade de resolução de problemas e considero o computador um verdadeiro amigo.\n\nApaixonado por tecnologia e inovação, estou sempre em busca de aprimorar minhas habilidades e contribuir de maneira significativa para o sucesso dos projetos em que me envolvo.",
        en: "Objective-driven Junior Back-End Developer with experience in cloud infrastructure, machine learning, generative AI, recommendation systems, and web API design.\n\nI am creative, possess strong problem-solving skills, and consider the computer a true friend.\n\nPassionate about technology and innovation, I am always seeking to improve my skills and contribute meaningfully to the success of the projects I am involved in."
   },

   supimpaDesc: {
      pt: "Você também pode visualizar este mesmo site na versão mais “supimpa”",
      en: "You can also view this same site in the most “supimpa” version"
   },
   clickingHere: {
    pt: "clicando aqui",
    en: "clicking here"
  },
   titleHolder:{
     pt: "OLÁ MUNDO! EU SOU",
     en: "HELLO WORLD! I AM"
   },

   name:{
     pt: "Nome",
     en: "Name"
   },
   birthday:{
     pt: "Aniversário",
     en: "Birthday"
   },
   march:{
     pt: "de Março",
     en: "March"
   },
   age:{
     pt: "Idade",
     en: "Age"
   },
   years:{
     pt: "anos",
     en: "years"
   },
   address:{
     pt: "Endereço",
     en: "Address"
   },
   aboutMe:{
     pt: "Sobre mim",
     en: "About me"
   },
   biography:{
     pt: "Biografia",
     en: "Biography"
   },
   biographyText:{
     pt: "Um entusiasta da computação desde criança (2004-2005), sempre fui muito curioso por qualquer assunto relacionado a computadores. Durante o ensino médio, cursei Técnico em Informática na FAETEC (2015), onde tive meu primeiro contato com programação. De todas as disciplinas, essa foi a que mais me interessou. Participei do grupo de robótica SIRLab, com o qual conquistamos o 3º lugar em uma competição estadual.\n\nSou formado em Tecnologia da Informação e da Comunicação pela FAETERJ Petrópolis (2024).\n\nNos meus momentos de lazer, quando não estou estudando ou participando de algum curso, sou um torcedor apaixonado do Paysandu.\n\nMinha experiência profissional é ampla e abrange diversas linguagens de programação e áreas da computação, incluindo infraestrutura em nuvem, desenvolvimento de software, liderança de equipe e inteligência artificial.",
     en: "A computing enthusiast since childhood (2004-2005), I have always been very curious about anything related to computers. During high school, I took a technical course in Information Technology at FAETEC (2015), where I had my first contact with programming. Among all the subjects, this was the one that interested me the most. I participated in the SIRLab robotics group, with which we achieved 3rd place in a state competition.\n\nI graduated in Information and Communication Technology from FAETERJ Petrópolis (2024).\n\nIn my leisure time, when I'm not studying or participating in some course, I am a passionate fan of Paysandu.\n\nMy professional experience is broad and covers various programming languages and areas of computing, including cloud infrastructure, software development, team leadership, and artificial intelligence."
    },
   education:{
     pt: "Educação",
     en: "Education"
   },
   experience:{
     pt: "Experiência",
     en: "Experience"
   },

   today:{
     pt: "Hoje",
     en: "Today"
   },
   internText: {
     pt: "Estagiário",
     en: "Intern"
   },
   wefitJobTitleTechDesigner:{
    pt: "Tech Designer",
    en: "Tech Designer"
  },
   mentorappJobDescriptionIntern:{
     pt: "Responsável pelo desenvolvimento e manutenção de aplicativos de software e modelos de machine learning.",
     en: "Responsible for the development and maintenance of software applications and machine learning models."
   },
   mentorappJobTitleSoftwareEngineer:{
     pt: "Engenheiro de software",
     en: "Software Engineer"
   },
   mentorappJobDescriptionSoftwareEngineer:{
     pt: "Responsável por projetar e implementar soluções de software, infraestrutura de nuvem, inteligência artificial e aplicativos mobile.",
     en: "Responsible for designing and implementing software solutions, cloud infrastructure, artificial intelligence and mobile applications."
   },
   camaraJobDescriptionIntern:{
    pt: "Responsável pelo suporte técnico aos colaboradores da Câmara, auxiliando nas configurações de servidores, elaboração de orçamentos para compras e manutenção dos computadores.",
    en: "Responsible for providing technical support to the City Council's staff, assisting with server configurations, preparing budgets for purchases and maintaining computers."
  },
   faetecTitle:{
     pt:"FUNDAÇÃO DE APOIO À ESCOLA TÉCNICA - CPTI PETRÓPOLIS",
     en: "FUNDAÇÃO DE APOIO À ESCOLA TÉCNICA - CPTI PETRÓPOLIS"
   },
   faeteCourseTitle:{
     pt:"Técnico em informática",
     en: "Technical Course"
   },
   faeteCourseDescription:{
     pt:"Técnico em informática",
     en: "Computer Technician"
   },
   faeterjTitle:{
     pt:"FACULDADE DE EDUCAÇÃO TENCNOLÓGICA DO ESTADO DO RIO DE JANEIRO",
     en:"FACULDADE DE EDUCAÇÃO TENCNOLÓGICA DO ESTADO DO RIO DE JANEIRO"
   },
   faeterjCourseTitle:{
     pt:"Ensino superior",
     en: "Ensino superior"
   },
   faeterjCourseDescription:{
     pt:"Tecnologia da Informação e da Comunicação",
     en: "Information and Communication Technology"
   },

   contact:{
      pt:"Contato",
      en: "Contact"
   },

   getInTouch:{
    pt:"Entre em contato",
    en: "Get In Touch"
   },

   contactDesc:{
    pt: "Se você tiver alguma sugestão, projeto ou até mesmo quiser dizer “olá”, preencha o formulário abaixo e responderei em breve.",
    en: "If you have any suggestions, projects or even want to say “hello”, please fill in the form below and I will respond shortly."
   },
   
   message:{
    pt:"Mensagem",
    en: "Message"
    
  },

  sendMessage:{
    pt: "Enviar mensagem",
    en: "Send message"
  },

  nullField:{
    pt: "Por favor, preencha os campos obrigatórios!",
    en:"Please Fill Required Fields!"
  },

  phone:{
    pt: "Telefone",
    en: "Phone"
  },

  sendMessageSuccess:{
    pt: "Sua mensagem foi recebida, em breve entrarei em contato.",
    en:"Your message has been received, we will contact you soon."
  },

  tools:{
    pt: "Ferramentas",
    en: "Tools"
  },

  languages:{
    pt: "Linguagens",
    en: "Languages"
  },

  toolsDesc:{
    pt: "Algumas linguagens e ferramentas que trabalhei nos últimos anos.",
    en: "Some languages and tools I've worked on in recent years"
  },

  summerSchool:{
    pt: "Escola de verão",
    en: "Summer school"
  },

  
  introductionMachineLearning:{
    pt: "Introdução ao Machine Learning",
    en: "Introduction to Machine Learning"
  },

  signalAnalisys:{
    pt: "Processamento digital de sinais para análise tempo-frequência",
    en: "Digital signal processing for time-frequency analysis"
  },


  introductionPythonParsl:{
    pt: "Introdução a Python-Parsl",
    en: "Introduction to Python-Parsl"
  },
  
  introductionDeepLearning:{
    pt: "Introdução ao Deep Learning",
    en: "Introduction to Deep Learning"
  },

  bigDataAlgorithms: {
    pt: "Algoritmos e Modelos de Programação para Big Data",
    en: "Algorithms and Programming Models for Big Data"
  },

  deepLearingMiniCourse: {
    pt: "Deep Learning",
    en: "Deep Learning"
  },

  designPatterns: {
    pt: "Padrões de Projeto",
    en: "Design Patterns"
  },

  extensionCourse: {
    pt: "Curso de extensão",
    en: "Extension course"
  }

}

export default STRINGS;