import { useEffect } from "react";
import ASSETS from "../resources/imageAssets";
import STRINGS from "../resources/strings";
import { ReactTyped } from "react-typed";

const PanelContent = ({language}) => {
  useEffect(() => {
    // const Typed = require("typed.js");
    // new Typed(".animated_title", {
    //   strings: ["Emmanuel Oliveira", "Backend Developer"],
    //   loop: true,
    //   smartBackspace: false,
    //   typeSpeed: 40,
    //   startDelay: 700,
    //   backDelay: 3e3,
    // });
  }, []);
  return (
    <div className="right_in">
      <div className="right_top">
        <div className="border1" />
        <div className="border2" />
        <div className="img_holder">
          <img src={ASSETS.meBackground} alt="me" />
          <div className="abs_img" data-bg-img={ASSETS.me} />
        </div>
        <div className="title_holder">
          <h5>{STRINGS.titleHolder[language]}</h5>
          <p className="h3">
          <ReactTyped
          backSpeed={70}
          strings={[
           "Emmanuel Oliveira", "Backend Developer"
            
          ]}
          typeSpeed={50}
          loop={true}
          />
            {/* <span className="animated_title" /> */}
          </p>
        </div>
      </div>
      {/* <div className="right_bottom">
        <a href="#contact">
          <span className="circle" />
          <span className="text">
            I’m available for a freelance job. Hire me
          </span>
        </a>
      </div> */}
    </div>
  );
};

export default PanelContent;
