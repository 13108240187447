const CERTIFICATES_BASE_URL = "https://files.emmanuel-oliveira.com/personal/"


const PERSONAL_ASSETS = {
    cvPt: CERTIFICATES_BASE_URL + "CV+-+Emmanuel+Oliveira.pdf",
    cvEn: CERTIFICATES_BASE_URL + "CV+-+Emmanuel+Oliveira.pdf",

    deepLearingMiniCourse: CERTIFICATES_BASE_URL + "2021-Minicurso+Deep+Learning+-2021.pdf",
    bigDataAlgorithms:CERTIFICATES_BASE_URL + "2021-Minicurso+Algoritmos+e+Modelos+de+Programa%C3%A7%C3%A3o+para+Big+Data.pdf",
    introductionDeepLearning: CERTIFICATES_BASE_URL + "2021-Introdu%C3%A7%C3%A3o+ao+Deep+Learning.pdf",
    pythonParsl: CERTIFICATES_BASE_URL + "2021-Introdu%C3%A7%C3%A3o+a+Python-Parsl.pdf",
    introductionMachineLearing: CERTIFICATES_BASE_URL + "2020-Introdu%C3%A7%C3%A3o+ao+Machine+Learning.pdf",
    signalAnalisys: CERTIFICATES_BASE_URL + "2020-Processamento+digital+de+sinais+para+an%C3%A1lise+tempo-frequ%C3%AAncia.pdf",
}




export default PERSONAL_ASSETS;